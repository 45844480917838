import { ReactComponent as ConstellationA } from "./assets/constellationA.svg";
import { ReactComponent as ConstellationB } from "./assets/constellationB.svg";
import { ReactComponent as TresLeonesLogo } from "./assets/logos/logo.svg";
import { ReactComponent as Stars } from "./assets/stars.svg";
import { ReactComponent as Sun } from "./assets/sun.svg";
import { ReactComponent as Title } from "./assets/title.svg";
import { ReactComponent as Arasa } from './assets/signs/arasa.svg';
import { ReactComponent as Pindo } from './assets/signs/pindo.svg';
import { ReactComponent as Jatei } from './assets/signs/jatei.svg';
import { ReactComponent as Ysypo } from './assets/signs/ysypo.svg';
import { ReactComponent as Tapekue } from './assets/signs/tapekue.svg';
import { ReactComponent as Mbokaja } from './assets/signs/mbokaja.svg';
import { ReactComponent as Nuati } from './assets/signs/nuati.svg';
import { ReactComponent as Ruda } from './assets/signs/ruda.svg';
import { ReactComponent as Tajy } from './assets/signs/tajy.svg';
import { ReactComponent as Kaare } from './assets/signs/ka_are.svg';
import { ReactComponent as Guavirami } from './assets/signs/guavirami.svg';
import { ReactComponent as Typycha } from './assets/signs/typycha.svg';
import { ReactComponent as LogoFacebook } from './assets/logos/logo-facebook.svg';
import { ReactComponent as LogoInsta } from './assets/logos/logo-instagram.svg';
import { ReactComponent as LogoLinkedin } from './assets/logos/logo-linkedin.svg';
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Modal from './components/Modal';
import Card from "./components/Card";
import api from "./services/api";
import DateStrip from "./components/DateStrip";
import { useNavigate } from "react-router";
import { Helmet } from "react-helmet";

function App() {

  const today = new Date()
  const { dateParam } = useParams();
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState(dateParam ? dateParam : today.toISOString().substring(0, 10));
  const [signs, setSigns] = useState([]);
  const navigate = useNavigate();
  const [selectedSign, setSelectedSign] = useState(0);

  const handleOpen = (sign) => {
    setSelectedSign(sign);
    setOpen(true);
  }

  useEffect(() => {
    setDate(dateParam);
  }, [dateParam])

  useEffect(() => {
    api.horoscopes.getMulti(null, null, null, null, { date: date }).then(
      (response) => {
        setSigns(response);
      }
    )
  }, [date])

  return (
    <div className="min-h-screen h-full bg-defaultbg overflow-auto">
      <Helmet
        title='Horóscopo Carrulim - Tres Leones'
        meta={[
          {
            property: "og:url",
            content: process.env.REACT_APP_BASE_URL
          },
          {
            property: "og:type",
            content: "article"
          },
          {
            property: "og:title",
            content: 'Horóscopo Carrulim - Tres Leones'
          },
          {
            property: "og:description",
            content: "Horóscopo diario guaraní"
          },
          {
            property: "og:image",
            content: "%PUBLIC_URL%/Thumbnail.png"
          }
        ]}
      />
      <Modal date={date} id={selectedSign} info={signs[selectedSign]} open={open} setOpen={setOpen} />
      <div className="flex flex-col overflow-hidden mt-10 z-20 space-y-8">
        <ConstellationA className="fixed w-1/4 left-0 top-15 sm:left-10 sm:top-[5%]" />
        <Stars className="fixed w-16 h-16 right-4 top-20 sm:w-auto sm:h-auto sm:right-[10%] sm:top-20" />
        <TresLeonesLogo className="w-3/4 sm:w-auto mx-auto" />
        <Title className="w-2/3 sm:w-auto mx-auto" />
        <ConstellationB className="fixed w-32 h-32 sm:w-auto sm:h-auto left-0 bottom-32 sm:left-[5%] sm:bottom-10" />
        <Sun className="fixed w-32 h-32 sm:w-64 sm:h-64 right-0 bottom-40 sm:right-0 sm:bottom-10" />
      </div>
      <div className="mx-auto mt-4 sm:mt-10 text-fontB">
        <p className="flex justify-center uppercase font-arsregular text-xl tracking-widest">{date.substring(5, 7) == '07' ? 'Julio' : 'Agosto'}</p>
        <DateStrip navigate={navigate} date={date} setDate={setDate} />
      </div>
      <div className="h-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-4/5 sm:mt-4 z-20 mx-auto gap-4 ">
        <Card id={0} setOpen={handleOpen} month="ENERO" sign={<Arasa />} />
        <Card id={1} setOpen={handleOpen} month="FEBRERO" sign={<Pindo />} />
        <Card id={2} setOpen={handleOpen} month="MARZO" sign={<Jatei />} />
        <Card id={3} setOpen={handleOpen} month="ABRIL" sign={<Ysypo />} />
        <Card id={4} setOpen={handleOpen} month="MAYO" sign={<Tapekue />} />
        <Card id={5} setOpen={handleOpen} month="JUNIO" sign={<Mbokaja />} />
        <Card id={6} setOpen={handleOpen} month="JULIO" sign={<Nuati />} />
        <Card id={7} setOpen={handleOpen} month="AGOSTO" sign={<Ruda />} />
        <Card id={8} setOpen={handleOpen} month="SETIEMBRE" sign={<Tajy />} />
        <Card id={9} setOpen={handleOpen} month="OCTUBRE" sign={<Kaare />} />
        <Card id={10} setOpen={handleOpen} month="NOVIEMBRE" sign={<Guavirami />} />
        <Card id={11} setOpen={handleOpen} month="DICIEMBRE" sign={<Typycha />} />
      </div>
      <div className="flex justify-center gap-x-4 mt-40 z-20">
        <a href="https://www.facebook.com/TresLeonesPy">
          <LogoFacebook />
        </a>
        <a href="https://www.instagram.com/tresleones/">
          <LogoInsta />
        </a>
        <a href="https://py.linkedin.com/company/tres-leones-i-c-s-a-">
          <LogoLinkedin />
        </a>
      </div>
      <div className="flex justify-center z-20">
        <div className="flex text-xs sm:text-lg px-4 flex-col text-center text-fontB font-arsregular mt-10">
          <p>Su consumo en exceso perjudica la salud. Prohibida su venta a menores de 20 años.</p>
          <p className="mt-2">Lo advierte el Ministerio de Salud Pública y Bienestar Social.</p>
          <p className="uppercase tracking-widest mt-12 mb-4">Disfrutá con Responsabilidad © Tres Leones 2022</p>
        </div>
      </div>
    </div>
  );
}

export default App;
