import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/solid'
import { useEffect, useState } from 'react'

const array = [
    '2022-07-01',
    '2022-07-02',
    '2022-07-03',
    '2022-07-04',
    '2022-07-05',
    '2022-07-06',
    '2022-07-07',
    '2022-07-08',
    '2022-07-09',
    '2022-07-10',
    '2022-07-11',
    '2022-07-12',
    '2022-07-13',
    '2022-07-14',
    '2022-07-15',
    '2022-07-16',
    '2022-07-17',
    '2022-07-18',
    '2022-07-19',
    '2022-07-20',
    '2022-07-21',
    '2022-07-22',
    '2022-07-23',
    '2022-07-24',
    '2022-07-25',
    '2022-07-26',
    '2022-07-27',
    '2022-07-28',
    '2022-07-29',
    '2022-07-30',
    '2022-07-31',
    '2022-08-01',
    '2022-08-02',
    '2022-08-03',
    '2022-08-04',
    '2022-08-05',
    '2022-08-06',
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function getDates(startDate, stopDate) {
    var dateArray = new Array();
    var currentDate = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date(currentDate).toISOString().substring(0, 10));
        currentDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
    }
    return dateArray;
}

export default function DateStrip(props) {

    const [tmpDate, setTmpDate] = useState(props.date)
    const today = new Date()
    const startDay = new Date(process.env.REACT_APP_START_DATE)
    const finalDay = new Date(process.env.REACT_APP_END_DATE)
    const [dates, setDates] = useState(today < finalDay ? getDates(startDay, today) : dates)

    useEffect(() => {
        setTmpDate(props.date);
    }, [props.date])

    return (
        <div className="py-3 flex items-center justify-center text-fontB text-lg">
            <div className="flex items-center justify-between">
                <nav className={classNames(tmpDate == dates[dates.length - 1] ? 'mr-8' : '', tmpDate == dates[0] ? 'ml-8' : '', "relative z-0 inline-flex rounded-md shadow-sm space-x-2 sm:space-x-8")} aria-label="Pagination">
                    <button
                        onClick={() => {
                            if (tmpDate != dates[0]) {
                                props.navigate(`/carrulim/${dates[dates.indexOf(tmpDate) - 1]}`)
                            }
                        }}
                        className="relative inline-flex items-center px-2 py-2 rounded-l-md font-medium  "
                    >
                        <span className="sr-only">Previous</span>
                        <ChevronLeftIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                        onClick={() => {
                            props.navigate(`/carrulim/${dates[dates.indexOf(tmpDate) - 2]}`)
                        }}
                        aria-current="page"
                        className={classNames(dates[dates.indexOf(tmpDate) - 2] == props.date ? "bg-fontB border rounded-full border-fontB text-white" : 'text-fontB hover:border hover:rounded-full hover:border-fontB', (tmpDate == dates[0] | tmpDate == dates[1]) ? "invisible" : "", "z-10 relative inline-flex leading-9 items-center px-4 py-2 font-medium")}
                    >
                        {(tmpDate == dates[0] | tmpDate == dates[1]) ? '' : dates[dates.indexOf(tmpDate) - 2].substring(8, 10)}
                    </button>
                    <button
                        onClick={() => {
                            props.navigate(`/carrulim/${dates[dates.indexOf(tmpDate) - 1]}`)
                        }}
                        className={classNames(dates[dates.indexOf(tmpDate) - 1] == props.date ? "bg-fontB border rounded-full border-fontB text-white" : 'text-fontB hover:border hover:rounded-full hover:border-fontB', (tmpDate == dates[0]) ? "invisible" : "", "relative inline-flex leading-9 items-center px-4 py-2 font-medium")}
                    >
                        {tmpDate == dates[0] ? '' : dates[dates.indexOf(tmpDate) - 1].substring(8, 10)}
                    </button>
                    <button
                        onClick={() => {
                            if (tmpDate != props.date) {
                                props.navigate(`/carrulim/${tmpDate}`)
                            }
                        }}
                        className={classNames(tmpDate == props.date ? "bg-fontB border rounded-full border-fontB text-white" : 'text-fontB hover:border hover:rounded-full hover:border-fontB', "leading-9 relative items-center px-4 py-2 font-medium")}
                    >
                        {tmpDate.substring(8, 10)}
                    </button>
                    <button
                        onClick={() => {
                            props.navigate(`/carrulim/${dates[dates.indexOf(tmpDate) + 1]}`)
                        }}
                        className={classNames(dates[dates.indexOf(tmpDate) + 1] == props.date ? "bg-fontB border rounded-full border-fontB text-white" : 'text-fontB hover:border hover:rounded-full hover:border-fontB', (tmpDate == dates[dates.length - 1]) ? "invisible" : "", "md:inline-flex leading-9 relative items-center px-4 py-2 font-medium")}
                    >
                        {tmpDate == dates[dates.length - 1] ? '' : dates[dates.indexOf(tmpDate) + 1].substring(8, 10)}
                    </button>
                    <button
                        onClick={() => {
                            props.navigate(`/carrulim/${dates[dates.indexOf(tmpDate) + 2]}`)
                        }}
                        className={classNames(dates[dates.indexOf(tmpDate) + 2] == props.date ? "bg-fontB border rounded-full border-fontB text-white" : 'text-fontB hover:border hover:rounded-full hover:border-fontB', (tmpDate == dates[dates.length - 1] | tmpDate == dates[dates.length - 2]) ? "invisible" : "", "relative inline-flex leading-9 items-center px-4 py-2 font-medium")}
                    >
                        {(tmpDate == dates[dates.length - 1] | tmpDate == dates[dates.length - 2]) ? '' : dates[dates.indexOf(tmpDate) + 2].substring(8, 10)}
                    </button>
                    <button
                        onClick={() => {
                            if (tmpDate != dates[dates.length - 1]) {
                                props.navigate(`/carrulim/${dates[dates.indexOf(tmpDate) + 1]}`)
                            }
                        }}
                        className="relative inline-flex items-center px-2 py-2 rounded-r-md font-medium "
                    >
                        <span className="sr-only">Next</span>
                        <ChevronRightIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                </nav>
            </div>
        </div>
    )
}