import { Fragment, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { ReactComponent as Horoscopo } from '../assets/Horoscopo.svg'
import { ReactComponent as AudioButton } from '../assets/audiobutton.svg'
import { ReactComponent as LogoFacebook } from '../assets/logos/logo-facebook.svg';
import { ReactComponent as LogoWhatsapp } from '../assets/logos/logo-whatsapp.svg';
import { ReactComponent as LogoTwitter } from '../assets/logos/logo-twitter.svg';
import { ReactComponent as ArasaIcon } from '../assets/icons/arasaIcon.svg';
import { ReactComponent as PindoIcon } from '../assets/icons/pindoIcon.svg';
import { ReactComponent as JateiIcon } from '../assets/icons/jateiIcon.svg';
import { ReactComponent as YsypoIcon } from '../assets/icons/ysypoIcon.svg';
import { ReactComponent as TapekueIcon } from '../assets/icons/tapekueIcon.svg';
import { ReactComponent as MbokajaIcon } from '../assets/icons/mbokajaIcon.svg';
import { ReactComponent as NuatiIcon } from '../assets/icons/nuatiIcon.svg';
import { ReactComponent as RudaIcon } from '../assets/icons/rudaIcon.svg';
import { ReactComponent as TajyIcon } from '../assets/icons/tajyIcon.svg';
import { ReactComponent as KaareIcon } from '../assets/icons/kaareIcon.svg';
import { ReactComponent as GuaviramiIcon } from '../assets/icons/guaviramiIcon.svg';
import { ReactComponent as TypychaIcon } from '../assets/icons/typychaIcon.svg';
import { FacebookShareButton } from 'react-share';

const icons = [
    <ArasaIcon className='max-h-[21.875rem]' />,
    <PindoIcon className='max-h-[21.875rem]' />,
    <JateiIcon className='max-h-[21.875rem]' />,
    <YsypoIcon className='max-h-[21.875rem]' />,
    <TapekueIcon className='max-h-[21.875rem]' />,
    <MbokajaIcon className='max-h-[21.875rem]' />,
    <NuatiIcon className='max-h-[21.875rem]' />,
    <RudaIcon className='max-h-[21.875rem]' />,
    <TajyIcon className='max-h-[21.875rem]' />,
    <KaareIcon className='max-h-[21.875rem]' />,
    <GuaviramiIcon className='max-h-[21.875rem]' />,
    <TypychaIcon className='max-h-[21.875rem]' />
]

const signsNames = [
    "Arasa",
    "Pindo",
    "Jate'i Ka'a",
    "Ysypo",
    "Tapekue",
    "Mbokaja",
    "Ñuatĩ Pytã",
    "Ruda",
    "Tajy",
    "Ka'arẽ",
    "Guavirami",
    "Typycha hũ"
]

export default function Modal(props) {

    const audio = new Audio(
        props.info?.audio
    );

    const start = () => {
        audio.play();
    }

    const stop = () => {
        audio.pause();
        audio.currentTime = 0;
    }

    const shareText = `Horóscopo de ${signsNames[props.id]} - ${props.date} \n ${props.info?.description} \n Para ver el tuyo, ingresa al enlace`

    return (
        <Transition.Root show={props.open} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={() => { stop(); props.setOpen(false); }}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-700 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed z-40 inset-0 overflow-y-auto">
                    <div className="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative bg-card rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 max-w-4xl sm:p-12">
                                <div className="sm:block absolute top-0 right-0 pt-4 pr-4">
                                    <button
                                        type="button"
                                        className="rounded-md flex font-arsregular text-fontB hover:text-fontB "
                                        onClick={() => {
                                            stop();
                                            props.setOpen(false)
                                        }}
                                    >
                                        Cerrar
                                        <span className="sr-only">Cerrar</span>
                                        <XIcon className="ml-4 h-6 w-6" aria-hidden="true" />
                                    </button>
                                </div>
                                <div className="mt-10 sm:flex sm:items-start">
                                    <div className="mx-auto w-full flex items-center justify-center">
                                        {icons[props.id]}
                                    </div>
                                    <div className="text-center sm:mt-0 sm:ml-20 sm:text-left">
                                        <Dialog.Title as="h3" className="text-lg leading-6 font-medium text-gray-900">
                                            <Horoscopo className="mx-auto mt-12 sm:mt-4 sm:mx-0" />
                                            <p className='text-fontB mt-1 text-2xl tracking-wider font-arsregular'>{props.date.substring(8, 10) + ' de ' + (props.date.substring(6, 7) == '7' ? 'Julio' : 'Agosto')}</p>
                                        </Dialog.Title>
                                        <div className="mt-4">
                                            <p className="text-md text-fontB font-arsregular leading-7">
                                                {props.info?.description ? props.info.description : 'Secreto'}
                                            </p>
                                        </div>
                                        {/* <div className='mt-8'>
                                            <button onClick={() => start()}>
                                                <AudioButton />
                                            </button>
                                        </div> */}
                                        <div className='mt-10'>
                                            <p className='uppercase text-sm tracking-widest text-fontB text-arsregular'>
                                                Compartí en tus redes
                                            </p>
                                            <div className='flex justify-center sm:justify-start gap-x-8 mt-4'>
                                                <FacebookShareButton url={`${process.env.REACT_APP_BASE_URL}/${props.date}`}>
                                                    <LogoFacebook />
                                                </FacebookShareButton>
                                                <a href={`https://twitter.com/intent/tweet?text=Horóscopo ${signsNames[props.id]} - ${props.date.substring(8, 10)} de ${props.date.substring(5, 7) == '07' ? 'Julio' : 'Agosto'}:%0a"${props.info?.description}"%0aPara ver tu horóscopo visita: ${process.env.REACT_APP_BASE_URL}/${props.date}`}>
                                                    <LogoTwitter />
                                                </a>
                                                <a href={`https://web.whatsapp.com/send?text=Horóscopo ${signsNames[props.id]} - ${props.date.substring(8, 10)} de ${props.date.substring(5, 7) == '07' ? 'Julio' : 'Agosto'}:%0a"${props.info?.description}"%0aPara ver tu horóscopo visita: ${process.env.REACT_APP_BASE_URL}/${props.date}`}>
                                                    <LogoWhatsapp />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}